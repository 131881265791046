import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/paratriennale/paratriennale/loveletter/src/templates/works-page-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const FixedRadio = makeShortcode("FixedRadio");
const Caption = makeShortcode("Caption");
const BpHr = makeShortcode("BpHr");
const LinkButton = makeShortcode("LinkButton");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`山本高之`}</h1>
    <p>{`二人一組となり、一人がこれまでに見た悪夢について話し、もう一人がその続きを考えて話す様子を収めた約 30 分の映像作品です。白い背景の前に横並びに二人が座り、話す人が入れ替わっていきます。手作りのお面で顔を隠す人もいれば、その人の代わりにその人の作品が登場する人もいますが、夢については言葉だけでやり取りが行われます。夢の話をすることを通して、相手のことを想像することが試みられている作品です。`}</p>
    <FixedRadio mdxType="FixedRadio">
  <iframe width="560" height="315" src="https://www.youtube.com/embed/znNB5bK21f0" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </FixedRadio>
    <Caption before={`作品`} medium={`映像　シングルチャンネル　30分31秒、2020年`} mdxType="Caption">
  《悪夢の続き》　
    </Caption>
    <BpHr type={`dot`} mdxType="BpHr" />
    <LinkButton to={`/works/mieko-nakagawa`} mdxType="LinkButton">応答する人: 中川美枝子</LinkButton>
    <LinkButton to={`/works/mieko-matsumoto`} mdxType="LinkButton">応答する人: 松本美枝子</LinkButton>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      